import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PolicyLinks from "./PolicyLinks";

export default function BackButton({
  showsLogo,
  backMessage,
  showsNews,
  whiteBackground,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  function handleClick() {
    if (whiteBackground) {
      navigate("/");
    } else if (showsLogo) {
      navigate("/news");
    } else {
      navigate("/");
    }
  }
  return (
    <div className={`back-button ${whiteBackground ? "white" : ""}`}>
      {!whiteBackground && (
        <img
          className="back-button-image"
          alt="arrow back"
          onClick={() => handleClick()}
          src={`${
            whiteBackground
              ? "/assets/icons/arrow-back-black.svg"
              : "/assets/icons/arrow-back.svg"
          }`}
        ></img>
      )}
      {showsLogo ? (
        <>
          <div
            onClick={() => handleClick()}
            className={`back-button-center-container ${
              whiteBackground ? "privacy-policy" : ""
            }`}
          >
            <img
              className={"news-header-nantek-logo "}
              alt="nantek-logo"
              src={`${
                whiteBackground
                  ? "/assets/horizontal-nantek-logo-and-title-black.svg"
                  : "/assets/horizontal-nantek-logo-and-title.svg"
              }`}
            ></img>
            {showsNews && (
              <p className="news-header-back-message italic-bold">
                {t("news.header.title")}
              </p>
            )}
          </div>
        </>
      ) : (
        <p className="news-header-back-message" onClick={() => handleClick()}>
          {t("news.header.back")}
        </p>
      )}
      {!showsNews && <PolicyLinks modifier="back"></PolicyLinks>}
    </div>
  );
}
