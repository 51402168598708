import React from "react";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BackButton from "../components/BackButton";
import { fetchNewsById } from "../features/news/newsTask";
import { Helmet } from "react-helmet";
import SocialMediaShareBar from "../components/SocialMediaShareBar";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PolicyLinks from "../components/PolicyLinks";
import showdown from "showdown";
export default function NewsDetail() {
  let dispatch = useDispatch();
  const { t } = useTranslation();
  let { id } = useParams();
  const converter = new showdown.Converter();
  const language = useSelector((state) => state.slider.language);
  const entry = useSelector((state) => state.news.entry);
  const [isScrolled, setIsScrolled] = useState(false);
  const page = useRef();
  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };
  window.addEventListener("scroll", handleScroll);

  function getFirstAvailableImageUrl(data) {
    const formats = ["large", "medium", "small", "thumbnail"];
    let url;

    for (const format of formats) {
      if (data.attributes.coverImage.data.attributes.formats[format]) {
        url = data.attributes.coverImage.data.attributes.formats[format].url;
        break;
      }
    }

    return url;
  }

  useEffect(() => {
    dispatch(fetchNewsById(id));
  }, [id, dispatch]);

  return (
    <div className="news-detail-box">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {`Nantek | `}
          {entry
            ? language === "en"
              ? entry.attributes.englishTitle
              : entry.attributes.title
            : "News"}
        </title>
        <meta name="description" content="Nantek website" />
      </Helmet>
      <div className="news-detail" ref={page}>
        <div className="news-detail-header">
          <BackButton showsLogo={true} showsNews={true} />
          <div
            className={isScrolled ? "top-shadow" : "top-shadow hidden"}
          ></div>
        </div>

        {entry && (
          <div className="news-detail-container">
            <div className="news-detail-header-container">
              <img
                className="news-detail-header-cover-img"
                alt="news-detail-cover-img"
                src={getFirstAvailableImageUrl(entry)}
              ></img>
              <div className="news-detail-header-content">
                <h2 className="news-detail-header-title">
                  {language === "en"
                    ? entry.attributes.englishTitle
                    : entry.attributes.title}
                </h2>
                <h3 className="news-detail-header-subtitle">
                  {language === "en"
                    ? entry.attributes.englishSubtitle
                    : entry.attributes.subtitle}
                </h3>
              </div>
            </div>
            <div className="news-detail-content-container">
              <p className="news-detail-content-date">
                {new Date(entry.attributes.createdAt).toLocaleDateString()}
              </p>
              {entry.attributes.mainVideo.data && (
                <video
                  controls={true}
                  src={entry.attributes.mainVideo.data.attributes.url}
                ></video>
              )}
              <div
                className="news-detail-content-main"
                dangerouslySetInnerHTML={{
                  __html:
                    language === "en"
                      ? converter.makeHtml(entry.attributes.englishContent)
                      : converter.makeHtml(entry.attributes.content),
                }}
              ></div>
              <SocialMediaShareBar entry={entry} />
            </div>
          </div>
        )}
      </div>
      <PolicyLinks modifier="news-footer"></PolicyLinks>
      <CookieConsent
        location="bottom"
        buttonText={t("cookies.banner.buttonText")}
        cookieName="nantek-cookie-consent"
        style={{ background: "#000" }}
        buttonStyle={{
          background: "#15525B",
          color: "#fff",
          fontSize: "0.8rem",
        }}
        expires={10}
      >
        {t("cookies.banner.message")}{" "}
        <Link className="cookies-link" to="/cookies-policy">
          {t("cookies.banner.linkText")}
        </Link>
      </CookieConsent>
    </div>
  );
}
