import React from "react";
import { defaultLanguage } from '../i18n'

export default function TimeLine({ props }) {
  const date = new Date(props.attributes.createdAt);
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return (
    <div
      className={
        "timeline-container " +
        (props.isFirst ? "opening" : "") +
        (props.isLast ? "closing" : "")
      }
    >
      <div className="timeline-line-up"></div>
      <div className="timeline-dot"></div>
      <div className="timeline-line-down"></div>
      <p
        className={
          "timeline-date " + (props.beginningOfMonth ? "new-month" : "")
        }
      >
        {capitalizeFirstLetter(
          date.toLocaleDateString(defaultLanguage, { month: "long", year: "numeric" })
        )}
      </p>
    </div>
  );
}
