import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PolicyLinks({ modifier }) {
  const { t, i18n } = useTranslation();

  return (
    <div className={`policy-links ${modifier}`}>
      <Link to={`/privacy-policy`} className={`link ${modifier}`}>
        {t("privacy.policy.title")}
      </Link>
      <span className="dash-divider">-</span>
      <Link to={`/legal-advice`} className={`link ${modifier}`}>
        {t("legal.advice.title")}
      </Link>
    </div>
  );
}
