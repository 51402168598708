import React from "react";

export default function VerticalSliderBorder({ isLeft }) {
  return (
    <div
      className={
        "vertical-slider-border-container " + (isLeft ? "left" : "right")
      }
    >
      <div className="vertical-slider-border-block">
        <img alt="bar" src="/assets/vector-vertical-bar.svg"></img>
        <img alt="asterisk" src="/assets/vector-vertical-asterisk.svg"></img>
        {!isLeft && (
          <img
            alt="nantek title"
            src="/assets/Nantek-title.svg"
            className="vertical-border-slider-title"
          ></img>
        )}
      </div>
      <div className="vertical-slider-border-block">
        {isLeft && (
          <img
            alt="nantek title"
            src="/assets/vector-nantek-title-left.svg"
            className="vertical-border-slider-title"
          ></img>
        )}
        <img alt="asterisk" src="/assets/vector-vertical-asterisk.svg"></img>
        <img alt="bar" src="/assets/vector-vertical-bar.svg"></img>
      </div>
    </div>
  );
}
