import React from "react";
import { Formik } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export default function ContactForm({ props }) {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [consentAccepted, setConsentAccepted] = useState(false);
  const contactBackground = useSelector(
    (state) => state.slider.contactBackground
  );

  const handleConsentChanged = (event) => {
    setConsentAccepted((current) => !current);
  };
  const { t, i18n } = useTranslation();
  return (
    <div className="slide">
      {contactBackground && contactBackground.mime.includes("image") && (
        <img
          className="slide-middle-content-cover-image"
          alt="coverImage"
          src={contactBackground.url}
        ></img>
      )}

      {contactBackground && !contactBackground.mime.includes("image") && (
        <video
          controls={false}
          playsInline={true}
          loop={true}
          autoPlay={true}
          muted={true}
          className="slide-middle-content-cover-image"
          alt="coverImage"
          src={contactBackground.url}
        ></video>
      )}

      <Formik
        initialValues={{
          name: "",
          lastName: "",
          email: "",
          phone: "",
          message: "",
        }}
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = t("contact.email.error.required");
          } else if (
            !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
              values.email
            )
          ) {
            errors.email = t("contact.email.error.invalid");
          }
          if (!values.name) {
            errors.name = t("contact.name.error.required");
          }
          if (!values.phone) {
            errors.phone = t("contact.phone.error.required");
          } else if (!/\+? ?(\d+[ -]?)+\d+/.test(values.phone)) {
            errors.phone = t("contact.phone.error.invalid");
          }
          if (!values.lastName) {
            errors.lastName = t("contact.lastName.error.required");
          }
          if (!values.message) {
            errors.message = t("contact.message.error.required");
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (!consentAccepted) {
            alert(t("contact.privacy.policy.mandatory"));
            setSubmitting(false);
            return;
          }
          fetch(
            "https://nantek-admin-panel.herokuapp.com/api/contact-form-entries",
            {
              method: "POST",
              body: JSON.stringify({
                data: values,
              }),
              headers: {
                "Content-type": "application/json",
              },
            }
          ).then((response) => {
            setSubmitting(false);
            setIsFormSubmitted(true);
            resetForm();
          });
        }}
        className="slide-middle-content"
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className="slide-middle-content">
            <h3 className="slide-middle-content-header">
              {t("contact.header.title")}
            </h3>
            <div className="slide-contact-form-subcontainer">
              <label>
                <input
                  className="slide-contact-form-input"
                  autoComplete="off"
                  type="text"
                  name="name"
                  placeholder={t("contact.name.placeholder")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                ></input>
                {errors.name && touched.name && (
                  <span className="slide-contact-form-input-error-message">
                    {errors.name}
                  </span>
                )}
              </label>
              <label>
                <input
                  className="slide-contact-form-input"
                  autoComplete="off"
                  type="text"
                  name="lastName"
                  placeholder={t("contact.lastName.placeholder")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                ></input>
                {errors.lastName && touched.lastName && (
                  <span className="slide-contact-form-input-error-message">
                    {errors.lastName}
                  </span>
                )}
              </label>
            </div>
            <div className="slide-contact-form-subcontainer">
              <label>
                <input
                  className="slide-contact-form-input"
                  type="email"
                  name="email"
                  placeholder={t("contact.email.placeholder")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                ></input>
                {errors.email && touched.email && (
                  <span className="slide-contact-form-input-error-message">
                    {errors.email}
                  </span>
                )}
              </label>
              <label>
                <input
                  className="slide-contact-form-input"
                  autoComplete="off"
                  type="tel"
                  name="phone"
                  placeholder={t("contact.phone.placeholder")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                ></input>
                {errors.phone && touched.phone && (
                  <span className="slide-contact-form-input-error-message">
                    {errors.phone}
                  </span>
                )}
              </label>
            </div>
            <div className="slide-contact-form-message">
              <label>
                <textarea
                  className="slide-contact-form-input"
                  name="message"
                  placeholder={t("contact.message.placeholder")}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                ></textarea>

                {errors.message && touched.message && (
                  <span className="slide-contact-form-input-error-message">
                    {errors.message}
                  </span>
                )}
              </label>
              <div className="privacy-policy-consent">
                <input
                  type="checkbox"
                  name="consent"
                  value={consentAccepted}
                  onChange={handleConsentChanged}
                />
                <label for="consent">
                  {t("contact.privacy.policy.consent")}
                  <Link
                    to="privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("contact.privacy.policy")}
                  </Link>
                </label>
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="slide-contact-form-btn"
              >
                {t("contact.send")}
              </button>

              {isFormSubmitted && (
                <i className="slide-contact-form-message-sent">
                  {t("contact.message.sent")}
                </i>
              )}
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}
