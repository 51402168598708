import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import data from "../../api/data.json";
import { defaultLanguage } from "../../i18n";

export const fetchSlides = createAsyncThunk("slider/fetchSlides", async () => {
  const response = await fetch(
    "https://nantek-admin-panel.herokuapp.com/api/slides?sort=createdAt:asc&populate=coverImage,contentImage"
  );
  const object = await response.json();
  return object.data;
});

export const fetchContactBackground = createAsyncThunk(
  "slider/fetchContactBackground",
  async () => {
    const response = await fetch(
      "https://nantek-admin-panel.herokuapp.com/api/contact-background?populate=background"
    );
    const object = await response.json();
    console.log("Data", object.data.attributes.background.data[0].attributes);
    return object.data.attributes.background.data[0].attributes;
  }
);

export const sliderTask = createSlice({
  name: "slider",
  initialState: {
    activeSlideIndex: 0,
    slides: [],
    language: defaultLanguage,
    contactBackground: null,
  },
  reducers: {
    updateActiveSlideIndex: (state, action) => {
      state.activeSlideIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSlides.fulfilled, (state, action) => {
      state.slides = action.payload;
    });

    builder.addCase(fetchContactBackground.fulfilled, (state, action) => {
      state.contactBackground = action.payload;
    });
  },
});

export const { goToSlide, updateActiveSlideIndex } = sliderTask.actions;
export default sliderTask.reducer;
