import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import TimeLine from "./TimeLine";
import showdown from "showdown";

export default function NewsPreview({ props, id, coverImage }) {
  const date = new Date(props.attributes.createdAt);
  const language = useSelector((state) => state.slider.language);
  const converter = new showdown.Converter();

  function convertToSlug(str) {
    str = str
      .replace(/[`~!@#$%^&*()_\-+=[\]{};:'"\\|/,.<>?\s]/g, " ")
      .toLowerCase();
    // trim spaces at start and end of string
    str = str.replace(/^\s+|\s+$/gm, "");
    // replace space with dash/hyphen
    str = str.replace(/\s+/g, "-");
    return str;
  }

  function getFirstAvailableImageUrl(data) {
    const formats = ["large", "medium", "small", "thumbnail"];
    let url;

    for (const format of formats) {
      if (data.attributes.coverImage.data.attributes.formats[format]) {
        url = data.attributes.coverImage.data.attributes.formats[format].url;
        break;
      }
    }

    return url;
  }

  return (
    <>
      <div className="news-preview-container">
        <Link
          className="news-link"
          to={`/news/${props.id}/${convertToSlug(
            language === "en"
              ? props.attributes.englishTitle
              : props.attributes.title
          )}`}
        ></Link>
        <div className="news-preview-header-container">
          <img
            className="news-preview-header-cover-img"
            alt="news-preview-cover-img"
            src={getFirstAvailableImageUrl(props)}
          ></img>
          <div className="news-preview-header-content">
            <h2 className="news-preview-header-title">
              {language === "en"
                ? props.attributes.englishTitle
                : props.attributes.title}
            </h2>
            <h3 className="news-preview-header-subtitle">
              {language === "en"
                ? props.attributes.englishSubtitle
                : props.attributes.subtitle}
            </h3>
          </div>
        </div>
        <div className="news-preview-content-container">
          <p className="news-preview-content-date">
            {date.toLocaleDateString()}
          </p>
          <div
            className="news-detail-content-main"
            dangerouslySetInnerHTML={{
              __html:
                language === "en"
                  ? converter.makeHtml(
                      props.attributes.englishContent.split("\n")[0]
                    )
                  : converter.makeHtml(props.attributes.content.split("\n")[0]),
            }}
          ></div>
          <button className="news-preview-content-plus-container">
            <img
              alt="plus"
              src="/assets/icons/plus-icon.svg"
              className="news-preview-content-plus-icon"
            ></img>
          </button>
        </div>
      </div>
      <TimeLine props={props} />
    </>
  );
}
