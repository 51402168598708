import React from "react";
import { useState, useRef } from "react";
import ReactMarkdown from "react-markdown";
import BackButton from "../components/BackButton";
import { Helmet } from "react-helmet";
import privacyPolicy from "../api/privacy-policy.json";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function PrivacyPolicy() {
  const [isScrolled, setIsScrolled] = useState(false);
  const page = useRef();
  const { t } = useTranslation();

  return (
    <div className="news-detail-box white">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Nantek | ${t("privacy.policy.title")}`}</title>
        <meta name="description" content="Nantek website" />
      </Helmet>
      <div className="news-detail white" ref={page}>
        <div className="news-detail-header">
          <BackButton
            showsLogo={true}
            showsNews={false}
            whiteBackground={true}
          />
          <div
            className={isScrolled ? "top-shadow" : "top-shadow hidden"}
          ></div>
        </div>

        <div className="news-detail-container white">
          <div className="news-detail-header-container">
            <div className="news-detail-header-content privacy-policy">
              <h2 className="news-detail-header-title white">
                POLÍTICA DE PRIVACIDAD
              </h2>
            </div>
          </div>
          <div className="news-detail-content-container white">
            <ReactMarkdown className="news-detail-content-main white">
              {privacyPolicy.data}
            </ReactMarkdown>
          </div>
        </div>
      </div>
      <CookieConsent
        location="bottom"
        buttonText={t("cookies.banner.buttonText")}
        cookieName="nantek-cookie-consent"
        style={{ background: "#000" }}
        buttonStyle={{
          background: "#15525B",
          color: "#fff",
          fontSize: "0.8rem",
        }}
        expires={10}
      >
        {t("cookies.banner.message")}{" "}
        <Link className="cookies-link" to="/cookies-policy">
          {t("cookies.banner.linkText")}
        </Link>
      </CookieConsent>
    </div>
  );
}
