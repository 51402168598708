import React from "react";
import { useTranslation } from "react-i18next";
import BackButton from "./BackButton";
import PolicyLinks from "./PolicyLinks";

export default function NewsHeader() {
  const { t, i18 } = useTranslation();
  return (
    <div className="news-header-container">
      <BackButton showsLogo={false} backMessage={""} />
      <div className="news-header-content">
        <img
          className="news-header-content-logo"
          alt="dark-nantek-logo"
          src="/assets/dark-nantek-logo.svg"
        ></img>
        <h2 className="news-header-logo-header">
          <i>{t("news.header.title")}</i>
        </h2>
        <PolicyLinks modifier="news"></PolicyLinks>
      </div>
      <div className="news-header-vertical-border left">
        <div className="news-header-vertical-border-block">
          <img
            className="news-header-border-icons"
            alt="asterisk"
            src="/assets/vector-vertical-asterisk.svg"
          ></img>
          <img
            className="news-header-border-icons"
            alt="bar"
            src="/assets/vector-vertical-bar.svg"
          ></img>
        </div>
      </div>

      <div className="news-header-horizontal-border">
        <div className="news-header-horizontal-border-block">
          <img
            className="news-header-border-icons"
            alt="bar"
            src="/assets/vector-horizontal-bar.svg"
          ></img>
          <img
            className="news-header-border-icons"
            alt="asterisk"
            src="/assets/vector-horizontal-asterisk.svg"
          ></img>
        </div>
        <div className="news-header-horizontal-border-block">
          <img
            className="news-header-border-icons"
            alt="asterisk"
            src="/assets/vector-horizontal-asterisk.svg"
          ></img>
          <img
            className="news-header-border-icons"
            alt="bar"
            src="/assets/vector-horizontal-bar.svg"
          ></img>
        </div>
      </div>
      <div className="news-header-vertical-border right">
        <div className="news-header-vertical-border-block">
          <img
            className="news-header-border-icons"
            alt="asterisk"
            src="/assets/vector-vertical-asterisk.svg"
          ></img>
          <img
            className="news-header-border-icons"
            alt="bar"
            src="/assets/vector-vertical-bar.svg"
          ></img>
        </div>
      </div>
    </div>
  );
}
