export default {
  translation: {
    "contact.header.title": "CONTACTO",
    "contact.name.placeholder": "Nombre*",
    "contact.name.error.required": "Nombre es un campo obligatorio",
    "contact.lastName.placeholder": "Apellido*",
    "contact.lastName.error.required": "Apellido es un campo obligatorio",
    "contact.email.placeholder": "Correo electrónico*",
    "contact.email.error.required":
      "Correo electrónico es un campo obligatorio",
    "contact.email.error.invalid": "Dirección de correo electrónico inválida",
    "contact.phone.placeholder": "Telefóno*",
    "contact.phone.error.required": "Teléfono es un campo obligatorio",
    "contact.phone.error.invalid": "Número de teléfono inválido",
    "contact.message.placeholder": "Mensaje...*",
    "contact.message.error.required": "Mensaje es un campo obligatorio",
    "contact.send": "ENVIAR",
    "contact.message.sent": "¡Mensaje enviado!",
    "contact.privacy.policy.consent": "He leído y acepto la ",
    "contact.privacy.policy": "política de privacidad.",
    "contact.privacy.policy.mandatory":
      "Debes aceptar la política de privacidad antes de continuar.",
    "cookies.banner.message":
      "Este sitio web no utiliza cookies para su funcionamiento.",
    "cookies.banner.linkText": "Política de cookies.",
    "cookies.banner.buttonText": "¡Entendido!",

    "privacy.policy.title": "Política de privacidad",
    "legal.advice.title": "Aviso Legal",
    "cookies.policy.title": "Política de cookies",
    "news.header.tab.title": "Noticias",
    "news.header.title": "NOTICIAS",
    "news.filter.sortBy": "ORDENAR POR:",
    "news.filter.older": "MÁS ANTIGUO",
    "news.filter.newer": "MÁS NUEVO",
    "news.header.back": "VOLVER AL SITIO PRINCIPAL",
    "news.results": "RESULTADOS",
    "news.results.not.found": "ESTA BÚSQUEDA NO ARROJÓ RESULTADOS",
  },
};
