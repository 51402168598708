import React from "react";
import Helmet from "react-helmet";
import Header from "../components/Header";
import SliderContainer from "./SliderContainer";
import { useDispatch } from "react-redux";
import {
  fetchSlides,
  fetchContactBackground,
} from "../features/slider/sliderTask";
import { useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Home() {
  let dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(fetchSlides());
    dispatch(fetchContactBackground());
  }, []);

  return (
    <div className="home-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Nantek</title>
        <meta name="description" content="Nantek website" />
      </Helmet>
      <Header />
      <SliderContainer />
      <CookieConsent
        location="bottom"
        buttonText={t("cookies.banner.buttonText")}
        cookieName="nantek-cookie-consent"
        style={{ background: "#000" }}
        buttonStyle={{
          background: "#15525B",
          color: "#fff",
          fontSize: "0.8rem",
        }}
        expires={10}
      >
        {t("cookies.banner.message")}{" "}
        <Link className="cookies-link" to="/cookies-policy">
          {t("cookies.banner.linkText")}
        </Link>
      </CookieConsent>
    </div>
  );
}
