import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PolicyLinks from "./PolicyLinks";

function Header() {
  const slides = useSelector((state) => state.slider.slides);
  const language = useSelector((state) => state.slider.language);
  const activeSlideIndex = useSelector(
    (state) => state.slider.activeSlideIndex
  );
  const { t, i18n } = useTranslation();

  function moveToSlide(index) {
    const event = new CustomEvent("carousel_change", { detail: index });
    document.body.dispatchEvent(event);
  }

  return (
    <div className="main-header-container">
      <PolicyLinks modifier="header"></PolicyLinks>
      <div className="header-container">
        <div className="header-logo-container">
          <img
            alt="nantek logo"
            src="/assets/nantek-logo.svg"
            className="header-logo"
          ></img>
        </div>
        <ul className={`header-nav ${slides.length > 0 ? "show" : ""}`}>
          {slides.map((slide, index) => {
            return (
              <div
                className={
                  "header-link-container " +
                  (index === activeSlideIndex ? "active" : "")
                }
                key={index}
              >
                <li
                  className="header-link"
                  onClick={() => {
                    moveToSlide(index);
                  }}
                >
                  {language === "en"
                    ? slide.attributes.englishTitle
                    : slide.attributes.title}
                </li>
                <span key={index}>/</span>
              </div>
            );
          })}

          <div
            className={
              "header-link-container " +
              (slides.length === activeSlideIndex ? "active" : "")
            }
            key={slides.length - 1}
          >
            <li
              className="header-link"
              onClick={() => {
                moveToSlide(slides.length);
              }}
            >
              {t("contact.header.title")}
            </li>
          </div>
        </ul>
        <Link
          to={`news`}
          className={`header-btn ${slides.length > 0 ? "show" : ""}`}
        >
          {t("news.header.title")}
        </Link>
      </div>
    </div>
  );
}

export default Header;
