import React from "react";

export default function HorizontalSliderBorder({ isTop }) {
  return (
    <div
      className={
        "horizontal-slider-border-container " +
        (isTop ? "align-end" : "align-start")
      }
    >
      <div className="horizontal-slider-border-block">
        <img alt="bar" src="/assets/vector-horizontal-bar.svg"></img>
        <img alt="asterisk" src="/assets/vector-horizontal-asterisk.svg"></img>
      </div>
      <div className="horizontal-slider-border-block">
        <img alt="asterisk" src="/assets/vector-horizontal-asterisk.svg"></img>
        <img alt="bar" src="/assets/vector-horizontal-bar.svg"></img>
      </div>
    </div>
  );
}
