import React, { useEffect } from "react";
import { useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Slide from "../components/Slide";
import HorizontalSliderBorder from "../components/HorizontalSliderBorder";
import VerticalSliderBorder from "../components/VerticalSliderBorder";
import ContactForm from "../components/ContactForm";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveSlideIndex } from "../features/slider/sliderTask";

export default function SliderContainer() {
  const slides = useSelector((state) => state.slider.slides);
  const owlCarousel = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.addEventListener(
      "carousel_change",
      (e) => {
        owlCarousel.current.to(e.detail, 300);
      },
      false
    );
  }, []);

  useEffect(() => {
    if (owlCarousel.current) {
      console.log("Carousel ready");
      owlCarousel.current.$ele.on("changed.owl.carousel", function (event) {
        console.log("Carousel on change");
        dispatch(updateActiveSlideIndex(event.item.index));
      });
    } else {
      console.log("No carousel ready");
    }
  }, [slides]);

  return (
    <div className={`slider-container ${slides.length > 0 ? "show" : ""}`}>
      <HorizontalSliderBorder isTop={true} />
      <div className="slider-middle">
        <VerticalSliderBorder isLeft={true} />
        <div className="owl-carousel-container">
          {slides.length > 0 && (
            <OwlCarousel ref={owlCarousel} items={1} nav className="owl-theme">
              {slides.map((slide, index) => {
                return <Slide key={index} props={slide} />;
              })}
              <ContactForm />
            </OwlCarousel>
          )}
        </div>
        <VerticalSliderBorder isLeft={false} />
      </div>
      <HorizontalSliderBorder isTop={false} />
    </div>
  );
}
