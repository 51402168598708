import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const fetchNews = createAsyncThunk("news/fetchNews", async () => {
  const response = await fetch(
    "https://nantek-admin-panel.herokuapp.com/api/entries?sort=createdAt:desc&populate=coverImage,mainVideo"
  );
  const object = await response.json();
  return object.data;
});

export const fetchNewsById = createAsyncThunk(
  "news/fetchNewsById",
  async (id) => {
    const response = await fetch(
      `https://nantek-admin-panel.herokuapp.com/api/entries/${id}/?sort=createdAt:desc&populate=coverImage,mainVideo`
    );
    const object = await response.json();
    return object.data;
  }
);

export const searchNews = createAsyncThunk("news/searchNews", async (term) => {
  const url = `https://nantek-admin-panel.herokuapp.com/api/entries?filters[$or][0][content][$containsi]=${term}&filters[$or][1][englishContent][$containsi]=${term}&filters[$or][2][title][$containsi]=${term}&filters[$or][3][englishTitle][$containsi]=${term}&filters[$or][4][subtitle][$containsi]=${term}&filters[$or][4][englishSubtitle][$containsi]=${term}&populate=coverImage,mainVideo`;
  const response = await fetch(url);
  const object = await response.json();
  return object.data;
});

function addTimelineTags(entries) {
  let currentMonth = 0;
  for (var entry of entries) {
    let date = new Date(entry.attributes.createdAt);
    let entryMonth = date.getMonth() + 1;
    entry.isFirst = false;
    entry.isLast = false;
    if (currentMonth !== entryMonth) {
      entry.beginningOfMonth = true;
      currentMonth = entryMonth;
    } else {
      entry.beginningOfMonth = false;
    }
  }

  if (entries.length >= 1) {
    entries[0].isFirst = true;
    entries[entries.length - 1].isLast = true;
  }

  return entries;
}

export const newsTask = createSlice({
  name: "news",
  initialState: {
    _entriesCache: [],
    entries: [],
    entry: undefined,
  },
  reducers: {
    resetEntryState(state, action) {
      state.entry = undefined;
    },

    resetEntries(state, action) {
      state.entries = addTimelineTags(state._entriesCache);
      state._entriesCache = [];
    },

    sort(state, action) {
      let entries = [];
      if (action.payload.isNewer) {
        entries = state.entries.sort((a, b) => {
          return (
            new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt)
          );
        });
      } else {
        entries = state.entries.sort((a, b) => {
          return (
            new Date(a.attributes.createdAt) - new Date(b.attributes.createdAt)
          );
        });
      }

      state.entries = addTimelineTags(entries);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNews.fulfilled, (state, action) => {
      state.entries = addTimelineTags(action.payload);
    });

    builder.addCase(searchNews.fulfilled, (state, action) => {
      state._entriesCache = state.entries;
      state.entries = addTimelineTags(action.payload);
    });

    builder.addCase(fetchNewsById.fulfilled, (state, action) => {
      state.entry = action.payload;
    });
  },
});

export const { sort, resetEntryState, resetEntries } = newsTask.actions;
export default newsTask.reducer;
