import React from "react";
import { useDispatch } from "react-redux";
import NewsHeader from "../components/NewsHeader";
import NewsContainer from "./NewsContainer";
import { fetchNews } from "../features/news/newsTask";
import { resetEntryState } from "../features/news/newsTask";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

export default function News() {
  let dispatch = useDispatch();

  const { t } = useTranslation();
  const title = t("news.header.tab.title");

  useEffect(() => {
    dispatch(resetEntryState());
    dispatch(fetchNews());
  }, [dispatch]);

  return (
    <div className="news-page-layout">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="Noticas Nantek" content="Nantek website" />
      </Helmet>
      <NewsHeader />
      <div className="news-divider"></div>
      <NewsContainer />
      <CookieConsent
        location="bottom"
        buttonText={t("cookies.banner.buttonText")}
        cookieName="nantek-cookie-consent"
        style={{ background: "#000" }}
        buttonStyle={{
          background: "#15525B",
          color: "#fff",
          fontSize: "0.8rem",
        }}
        expires={10}
      >
        {t("cookies.banner.message")}{" "}
        <Link className="cookies-link" to="/cookies-policy">
          {t("cookies.banner.linkText")}
        </Link>
      </CookieConsent>
    </div>
  );
}
