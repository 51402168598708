import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

export default function SocialMediaShareBar({ entry }) {
  const language = useSelector((state) => state.slider.language);
  const [isCopyToClipboard, setIscopyToClipboard] = useState(false);
  const copyToClipboard = (str) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      setIscopyToClipboard(true);
      setTimeout(() => {
        setIscopyToClipboard(false);
      }, 3000);
      return navigator.clipboard.writeText(str);
    }
    return Promise.reject("The Clipboard API is not available.");
  };
  const title = () => {
    if (language === "en") {
      return entry.attributes.englishTitle;
    } else {
      return entry.attributes.title;
    }
  };

  return (
    <div className="social-media-share-bar">
      <a
        href={`https://x.com/intent/tweet?text=${title()}&url=${
          window.location.href
        }`}
        className="social-media-share-bar-btn"
        target="_blank"
        rel="noreferrer"
      >
        <img alt="x" src="/assets/icons/x-icon.svg"></img>
      </a>
      <a
        href={`https://api.whatsapp.com/send?text=${title()} ${
          window.location.href
        }`}
        target="_blank"
        rel="noreferrer"
        className="social-media-share-bar-btn"
      >
        <img alt="whatsapp" src="/assets/icons/whatsapp-icon.svg"></img>
      </a>
      <button
        onClick={() => copyToClipboard(window.location.href)}
        className="social-media-share-bar-btn"
      >
        <img alt="link" src="/assets/icons/link-icon.svg"></img>
      </button>
      {isCopyToClipboard && <span>Enlace copiado</span>}
    </div>
  );
}
