import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./styles.scss";
import Home from "./layout/Home";
import ErrorPage from "./components/ErrorPage.jsx";
import News from "./layout/News.jsx";
import NewsDetail from "./layout/NewsDetail";
import PrivacyPolicy from "./layout/PrivacyPolicy";
import CookiesPolicy from "./layout/CookiesPolicy";
import LegalAdvice from "./layout/LegalAdvice";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/news/:id/:title",
      element: <NewsDetail />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/news",
      element: <News />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/cookies-policy",
      element: <CookiesPolicy />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/legal-advice",
      element: <LegalAdvice />,
      errorElement: <ErrorPage />,
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
