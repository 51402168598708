export default {
  translation: {
    "contact.header.title": "CONTACT",
    "contact.name.placeholder": "Name*",
    "contact.name.error.required": "Name is a required field",
    "contact.lastName.placeholder": "Last Name*",
    "contact.lastName.error.required": "Last Name is a required field",
    "contact.email.placeholder": "Email*",
    "contact.email.error.required": "Email is a required field",
    "contact.email.error.invalid": "Invalid email address",
    "contact.phone.placeholder": "Phone*",
    "contact.phone.error.required": "Phone is a required field",
    "contact.phone.error.invalid": "Invalid phone number",
    "contact.message.placeholder": "Message...*",
    "contact.message.error.required": "Message is a required field",
    "contact.send": "SEND",
    "contact.message.sent": "¡Message sent!",
    "contact.privacy.policy.consent": "I have read and accept the ",
    "contact.privacy.policy": "privacy policy.",
    "contact.privacy.policy.mandatory":
      "You must accept privacy policy terms before continue.",
    "privacy.policy.title": "Privacy policy",
    "legal.advice.title": "Legal advice",
    "cookies.policy.title": "Cookies policy",

    "cookies.banner.message": "This website does not use cookies.",
    "cookies.banner.linkText": "Cookies policy.",
    "cookies.banner.buttonText": "Got it!",

    "news.header.title": "NEWS",
    "news.header.tab.title": "News",
    "news.filter.sortBy": "SORT BY:",
    "news.filter.older": "OLDER",
    "news.filter.newer": "NEWER",
    "news.header.back": "BACK TO MAIN PAGE",
    "news.results": "RESULTS",
    "news.results.not.found": "MATCH NOT FOUND",
  },
};
