import React from "react";
import NewsPreview from "../components/NewsPreview";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { sort } from "../features/news/newsTask";
import { searchNews, fetchNews, resetEntries } from "../features/news/newsTask";

export default function NewsContainer() {
  const news = useSelector((state) => state.news.entries);
  const { t } = useTranslation();
  const [isSelected, setIsSelected] = useState(true);
  const options = [
    { title: t("news.filter.newer"), isNewer: true },
    { title: t("news.filter.older"), isNewer: false },
  ];
  const [selectedItem, setSelectedItem] = useState(options[0]);
  const dispatch = useDispatch();
  const [hasResults, setHasResults] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const inputSearch = useRef();
  useEffect(() => {
    if (isSearching) {
      inputSearch.current.focus();
    }
  }, [isSearching]);

  const handleSearch = (e) => {
    if (!isSearching) {
      setIsSearching(!isSearching);
    }
  };
  const handleKeyDown = (e) => {
    if ((e.keyCode === 13) & (inputSearch.current.value !== "")) {
      document.activeElement.blur();
      dispatch(searchNews(inputSearch.current.value));
      setHasResults(true);
    }
  };
  function optionFilter(option) {
    return option.title !== selectedItem.title;
  }
  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = (event) => {
    setIsScrolled(event.currentTarget.scrollTop > 0);
  };
  const handleCancelClick = () => {
    inputSearch.current.value = "";
    if (hasResults) {
      dispatch(resetEntries());
    }
    setHasResults(false);
    setIsSearching(false);
    dispatch(fetchNews());
    document.activeElement.blur();
  };

  return (
    <div className="news-container">
      <div className="news-container-bar">
        {!hasResults && (
          <div
            className={isScrolled ? "top-shadow" : "top-shadow hidden"}
          ></div>
        )}
        <ul
          className={
            "news-container-bar-filter " + (isSearching ? "expanded" : "")
          }
          onClick={() => {
            setIsSelected(!isSelected);
          }}
        >
          <li className="news-container-bar-filter-block">
            <span className="news-container-bar-filter-title">
              {t("news.filter.sortBy")}
            </span>
            <span className="news-container-bar-selected-filter">
              {selectedItem.title}
            </span>
          </li>
          {!isSelected &&
            options.filter(optionFilter).map((option, index) => {
              return (
                <li
                  className={"news-container-bar-filter-block options"}
                  key={`option-${index}`}
                  onClick={() => {
                    setSelectedItem(option);
                    setIsSelected(true);
                    dispatch(sort(option));
                  }}
                >
                  {option.title}
                </li>
              );
            })}
        </ul>
        <div
          className={
            "news-container-bar-search-container " +
            (isSearching ? "expanded" : "")
          }
          onClick={handleSearch}
        >
          <img
            className="news-container-bar-search-icon"
            alt="search-icon"
            src="/assets/icons/search-icon.svg"
          ></img>
          <input
            autoFocus
            className="news-container-bar-search-input"
            type="text"
            ref={inputSearch}
            onKeyDown={handleKeyDown}
          />
          <button
            className="news-container-bar-cancel-btn"
            onClick={handleCancelClick}
          >
            <img alt="cancel-icon" src="/assets/icons/cancel-icon.svg"></img>
          </button>
        </div>
      </div>
      <div className="result-container">
        {hasResults && (
          <h4 className="news-container-search-result">{t("news.results")}</h4>
        )}
        {hasResults && news.length === 0 && (
          <span className="news-container-search-result">
            {t("news.results.not.found")}
          </span>
        )}
        <div className={isScrolled ? "top-shadow" : "top-shadow hidden"}></div>
      </div>

      <div className="news-container-previewer" onScroll={handleScroll}>
        {news.map((news, index) => {
          return (
            <>
              <NewsPreview key={`news-${index}`} props={news} id={news.id} />
            </>
          );
        })}
      </div>
      <div className="bottom-shadow"></div>
    </div>
  );
}
