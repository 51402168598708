import React from "react";
import { useSelector } from "react-redux";
import showdown from "showdown";

export default function Slide({ props }) {
  const language = useSelector((state) => state.slider.language);
  const converter = new showdown.Converter();

  return (
    <div className="slide">
      {props.attributes.coverImage.data.attributes.mime.includes("image") && (
        <img
          className="slide-middle-content-cover-image"
          alt="coverImage"
          src={props.attributes.coverImage.data.attributes.url}
        ></img>
      )}

      {!props.attributes.coverImage.data.attributes.mime.includes("image") && (
        <video
          controls={false}
          playsInline={true}
          loop={true}
          autoPlay={true}
          muted={true}
          className="slide-middle-content-cover-image"
          alt="coverImage"
          src={props.attributes.coverImage.data.attributes.url}
        ></video>
      )}

      <div className="slide-middle-content">
        <h3
          className="slide-middle-content-header"
          style={{ color: props.attributes.titleTextColor }}
        >
          {language === "en"
            ? props.attributes.englishTitle
            : props.attributes.title}
        </h3>
        <p
          className="slide-middle-content-text"
          style={{ color: props.attributes.contentTextColor }}
          dangerouslySetInnerHTML={{
            __html:
              language === "en"
                ? converter.makeHtml(props.attributes.englishContent)
                : converter.makeHtml(props.attributes.content),
          }}
        ></p>
        <img
          alt="contentImage"
          src={props.attributes.contentImage.data.attributes.url}
          className="slide-middle-content-icon"
        ></img>
      </div>
    </div>
  );
}
